import { GeoJSONPoint } from '../../../common/models/geo.model'

export type Park = {
  // id: number | undefined // TODO: do we need it?
  reference: string
  name: string
  coords: GeoJSONPoint
  activations: number
  geometries?: number
}

export type ParkArea = {
  reference: string
  name: string
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  geo: any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  metadata: any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  labelPoint?: any
  area?: number
}

export type ParkDetails = Park & {
  attempts: number
  qsos: number
  numGeometries?: number
  timestamp?: Date
  dxccEntity?: string
  voivodeships?: string
  grid6?: string
  area?: number
}

export type ParkActivation = {
  callsign: string
  date: Date
  qsosCW: number
  qsosPhone: number
  qsosData: number
  qsosTotal: number
}

export enum ParkRelationType {
  WITHIN = 'WITHIN',
  CONTAINS = 'CONTAINS',
  INTERSECTS = 'INTERSECTS',
}

export type ParkRelation = {
  reference: string
  name: string
  type: ParkRelationType
  area: number
  coords: GeoJSONPoint
}

export type SummitWithin = {
  summitCode: string
  name: string
  altM: number
  altFt: number
  coords: GeoJSONPoint
}
