/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import { geoFromClibboardAreaSelector, selectedParkDetailsSelector } from '../../../parks/parks.selectors'
import { ParkDetails } from '../../../parks/parks.model'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { open as modalOpen } from '../../../../auth/auth.slice'
// import { notImplementedSnack } from '../../../map.slice'
import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ModalFormType } from '../../../../auth/auth.model'
import { setParkGeoAreaFromClipboard, uploadParkGeoArea } from '../../../parks/parks.slice'
import { FileUpload } from '@mui/icons-material'
import { setSnackbar } from '../../../map.slice'

export const ParkDetailsComponent = () => {
  const dispatch = useDispatch<any>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const geoFromClipboard = useSelector(geoFromClibboardAreaSelector)
  const onAddSpatialClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const selectedParkDetails: ParkDetails | null = useSelector(selectedParkDetailsSelector)
  if (!selectedParkDetails) return

  const { reference, activations, dxccEntity, voivodeships, grid6, coords, numGeometries } = selectedParkDetails
  // const hasArea = area && numGeometries
  const hasArea = !!numGeometries

  // TODO: this should come from api
  // const flagName = flagsService.parkOrSummitCountryToFlag(reference.split('-')[0])
  const flagName = reference.split('-')[0].toLowerCase()

  // const fromFileClick = () => {
  //   // dispatch(notImplementedSnack())
  //   dispatch(setParkGeoAreaFromClipboard(''))
  // }

  const fromClipboardClick = () => {
    dispatch(modalOpen(ModalFormType.ADD_GEO_FROM_CLIPBOARD))
    handleClose()
  }

  const onUploadSpatialClick = async () => {
    try {
      dispatch(uploadParkGeoArea({ reference, geo: geoFromClipboard[0] }))
      dispatch(setSnackbar("Spatial uploaded. You'll receive a message when it's approved."))
      dispatch(setParkGeoAreaFromClipboard(''))
    } catch (error) {
      dispatch(setSnackbar('Something went wrong. Spatial cannot be uploaded.'))
    }
    // console.log('*** onUploadSpatialClick')
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableCell align="left">DXCC Entity</TableCell>
            <TableCell align="right">
              <span className={`fi fi-${flagName}`} style={{ marginRight: 4, boxShadow: '0px 0px 6px' }}></span> <b>{dxccEntity}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Voivodeship(s)</TableCell>
            <TableCell align="right">
              <b>{voivodeships}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Locator grid(s)</TableCell>
            <TableCell align="right">
              <b>{grid6}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Geo coordinates</TableCell>
            <TableCell align="right">
              <b>{coords.coordinates.join(', ')}</b>
            </TableCell>
          </TableRow>
          {hasArea ? (
            <>
              <TableRow>
                <TableCell align="left">Number of geometries</TableCell>
                <TableCell align="right">
                  <b>{numGeometries}</b>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell align="left">
                  <InfoChip text="No geometry defined" />
                </TableCell>
                <TableCell align="right">
                  {geoFromClipboard && geoFromClipboard.length === 1 ? (
                    <Button variant="contained" size="small" onClick={onUploadSpatialClick} startIcon={<FileUpload />}>
                      Upload
                    </Button>
                  ) : (
                    <Button variant="contained" size="small" onClick={onAddSpatialClick} startIcon={<AddCircleIcon />}>
                      Add spatial
                    </Button>
                  )}
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {/* <MenuItem onClick={fromFileClick}>From file</MenuItem> */}
                    <MenuItem onClick={fromClipboardClick}>From clipboard</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            </>
          )}
          {activations === 0 && (
            <TableRow>
              <TableCell align="left">Activations</TableCell>
              <TableCell align="right">{activations === 0 && <InfoChip text="Never activated" />}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
