/* eslint-disable @typescript-eslint/no-explicit-any */
import * as L from 'leaflet'
import { Park, ParkActivation, ParkArea, ParkDetails, ParkRelation } from './parks.model'
import { Const } from '../../../common'
import { SelectedObject, SelectedObjectType } from '../aside/aside.model'
import { Flag, FlagType } from '../flags/flags.model'

const getRadius = (zoom: number) => {
  if (zoom >= 10) {
    return 8
  } else {
    return 6
  }
}

const getTooltipText = (park: Park) => {
  const activationsCount = park.activations ? `${park['activations']} activation${park.activations === 1 ? '' : 's'}` : 'Never activated'
  const tooltip = `<b>${park.reference}</b> ${park.name}<br />${activationsCount}`
  return tooltip
}

const circleWithText = (park: Park, selectedFlags: FlagType[], txt: string, circleOptions: any, onClick: any) => {
  const flagsContainer = `<div class="flags-container">
    ${selectedFlags.some(x => x === FlagType.BOOKMARK) ? '<div class="bookmark"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.ACTIVATED) ? '<div class="activated"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.HUNTED) ? '<div class="hunted"></div>' : ''}
  </div>`

  const icon = L.divIcon({
    html: '<div class="txt">' + txt + '</div>' + flagsContainer,
    className: 'circle-with-txt',
    iconSize: [20, 20],
  })
  const circle = L.circleMarker([park.coords.coordinates[1], park.coords.coordinates[0]], circleOptions)
  const marker = L.marker([park.coords.coordinates[1], park.coords.coordinates[0]], {
    icon: icon,
  })
    .addEventListener('click', () => onClick(park))
    .bindTooltip(getTooltipText(park))
  const group = L.layerGroup([circle, marker])

  return group
}

const getParkDataFromResponse = (response: any): ParkDetails | null => {
  const { activations, attempts, coords, name, numberOfGeometries, area, qsos, reference, timestamp, metadata } = response
  // const { details } = metadata
  if (!metadata?.details) {
    return null
  }
  const { entityName, locationDesc, locationName, grid6 } = metadata.details

  const voivodeships =
    locationDesc?.split(',').length > 1
      ? locationDesc
          .split(',')
          .reduce((acc: string, curr: string, index: number) => `${acc} ${curr} ${locationName.split(',')[index]}, `, '')
          .slice(0, -2)
      : `${locationDesc} ${locationName}`

  return {
    activations,
    attempts,
    coords,
    name,
    numGeometries: numberOfGeometries,
    area,
    qsos,
    reference,
    timestamp,
    dxccEntity: entityName,
    voivodeships,
    grid6,
  }
}

const getParkActivationsFromResponse = (response: any): ParkActivation[] | null => {
  if (!response?.metadata?.activations || !response.metadata.activations.length) {
    return null
  }

  return response.metadata.activations.map(({ activeCallsign, qso_date, qsosCW, qsosDATA, qsosPHONE, totalQSOs }: any) => {
    const date = new Date(`${qso_date.substring(0, 4)}-${qso_date.substring(4, 6)}-${qso_date.substring(6, 8)}`)
    return {
      callsign: activeCallsign,
      date,
      qsosCW,
      qsosData: qsosDATA,
      qsosPhone: qsosPHONE,
      qsosTotal: totalQSOs,
    }
  })
}

const getUniqueParkRelations = (relatedParks: ParkRelation[]): ParkRelation[] =>
  relatedParks.reduce((a: ParkRelation[], b: ParkRelation) => {
    if (!a.some((x: ParkRelation) => x.reference === b.reference)) {
      a.push(b)
    }
    return a
  }, [])

const getMarkerForPark = (park: Park, zoom: number, selectedObject: SelectedObject | null | undefined, flags: Flag[], onClick: any) => {
  const isSelected = selectedObject && selectedObject.type === SelectedObjectType.PARK && selectedObject.reference === park.reference
  const className: string = `park-point-base park-point-${zoom} ${park['activations'] ? '' : 'park-not-activated'} ${isSelected ? 'park-selected' : ''} ${
    !park['geometries'] && park['reference'].startsWith('PL-') ? 'park-no-gemetry' : ''
  }`
  const selectedFlags = flags.filter(x => x.reference === park.reference && x.object === SelectedObjectType.PARK).map(x => x.type)

  // L.circleMarker([park.coords.coordinates[1], park.coords.coordinates[0]], { radius: getRadius(zoom), className })
  return circleWithText(
    park,
    selectedFlags,
    '',
    {
      radius: getRadius(zoom),
      className,
      weight: 2,
      color: Const.colors.parkPointBackgroundColor,
    },
    onClick
  )
}

const getAreaForPark = (parkArea: ParkArea, park: Park, zoom: number, selectedObject: SelectedObject | null | undefined, onClick: any) => {
  // const className: string = `park-area-base park-area-${zoom} ${park.activations ? '' : 'park-not-activated'}`
  console.log(parkArea)
  if (!zoom) {
    console.log('TODO: no zoom')
  }

  const geoJsonFeature: any = {
    type: 'Feature',
    properties: {
      name: park.name,
    },
    geometry: JSON.parse(parkArea.geo),
  }

  const getAreaFillColor = (park: any, selectedObject: SelectedObject | null | undefined) => {
    if (!park['activations']) {
      return 'gray'
    } else if (park.reference === selectedObject?.reference) {
      return 'blue'
    } else {
      return 'lightseagreen'
    }
  }

  const geoJSONStyles = (park: any, selectedObject: SelectedObject | null | undefined) => {
    // // const fillColor = park.activations ? 'lightseagreen' : 'gray'
    return {
      fillColor: getAreaFillColor(park, selectedObject),
      weight: 2,
      opacity: 1,
      color: park.reference === selectedObject ? 'blue' : 'white',
      dashArray: '3',
      fillOpacity: 0.2,
    }
  }

  return (
    L.geoJSON(geoJsonFeature, { style: geoJSONStyles(park, selectedObject) })
      .addEventListener('click', () => onClick(parkArea))
      // .addEventListener('', () => onClick(park))
      .bindTooltip(getTooltipText(park))
  )
}

const getGeoFromClipboard = (item: any) => {
  console.log(item)
  const geoJsonFeature: any = {
    type: 'Feature',
    properties: {
      name: 'NEW',
    },
    geometry: JSON.parse(item),
  }

  const geoJSONStyles = () => {
    return {
      fillColor: 'red',
      weight: 2,
      opacity: 1,
      color: 'orange',
      dashArray: '3',
      fillOpacity: 0.2,
    }
  }

  return L.geoJSON(geoJsonFeature, { style: geoJSONStyles() })
  // .addEventListener('click', () => onClick(parkArea))
  // .addEventListener('', () => onClick(park))
  // .bindTooltip(getTooltipText(park))
}

export const parksService = {
  getParkDataFromResponse,
  getParkActivationsFromResponse,
  getUniqueParkRelations,
  getMarkerForPark,
  getAreaForPark,
  getGeoFromClipboard,
}
