/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import { AsideTop } from './AsideTop'
import { isOpenSelector } from './aside.selectors'
import { AsideBottom } from './AsideBottom'
import { AsideContent } from './AsideContent'
import { AsidePanelContainer } from './Aside.styles'
import { isLoggedInSelector } from '../../user/user.selectors'
import { close as asideClose } from '../aside/aside.slice'
import { open as modalOpen } from '../../auth/auth.slice'
import { ModalFormType } from '../../auth/auth.model'

const LoginPromptContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`

export const AsidePanel = ({ addActivationSpotClick }: any) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(isOpenSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)

  if (!isOpen) return null

  const handleButtonClick = () => {
    dispatch(asideClose())
    dispatch(modalOpen(ModalFormType.SIGNIN))
  }

  return (
    <AsidePanelContainer>
      {isLoggedIn ? (
        <>
          <AsideTop />
          <AsideContent />
          <AsideBottom addActivationSpotClick={addActivationSpotClick} />
        </>
      ) : (
        <LoginPromptContainer>
          <p>
            You need to be authenticated
            <br />
            to see the details
          </p>
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleButtonClick}>
            Sign In
          </Button>
        </LoginPromptContainer>
      )}
    </AsidePanelContainer>
  )
}
