import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { close as asideClose } from '../aside/aside.slice'
import { clearToken, open as modalOpen } from '../../auth/auth.slice'
import { getCallsignSelector, isLoggedInSelector } from '../../user/user.selectors'
import Menu from '@mui/material/Menu'
import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { authenticationService } from '../../../common/services/authentication.service'
import { clear } from '../../user/user.slice'
import { notImplementedSnack, setSnackbar } from '../map.slice'
import { useNavigate } from 'react-router-dom'
import { clearFlags } from '../flags/flags.slice'
import Chip from '@mui/material/Chip'
import { ModalFormType } from '../../auth/auth.model'

export const AvatarComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const callsign = useSelector(getCallsignSelector) || 'Sign in'
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const sxStyles = isLoggedIn ? { color: '#1976d2', cursor: 'pointer' } : { '&:hover': { color: 'black' }, cursor: 'pointer' }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isLoggedIn) {
      setAnchorEl(event.currentTarget)
      // console.log('isLoggedIn')
    } else {
      dispatch(asideClose())
      dispatch(modalOpen(ModalFormType.SIGNIN))
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutClicked = () => {
    handleClose()
    dispatch(clearToken())
    dispatch(clear())
    dispatch(clearFlags())
    dispatch(setSnackbar('You have been signed out'))
    authenticationService.removeToken()
  }
  const profileClicked = () => {
    dispatch(notImplementedSnack())
    handleClose()
  }

  const admClicked = () => {
    navigate('/adm')
    handleClose()
  }

  // const handleClick = () => {
  //   if (isLoggedIn) {
  //     console.log('isLoggedIn')
  //   } else {
  //     dispatch(asideClose())
  //     dispatch(modalOpen())
  //   }
  // }

  return (
    <div>
      {!isLoggedIn && <Chip label="Sign in to see all the features" variant="filled" color="success" size="medium" />}
      <IconButton onClick={handleMenu} disableRipple>
        <Tooltip title={callsign} placement="left">
          <Avatar sx={sxStyles} />
        </Tooltip>
      </IconButton>
      <Menu
        // MenuListProps={{ sx: { mt: 3 } }}
        PaperProps={{ sx: { mt: 5, minWidth: 140 } }}
        id="menu-user"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {callsign === 'SP5ORI' && <MenuItem onClick={admClicked}>Admin panel</MenuItem>}
        <MenuItem onClick={profileClicked}>Profile</MenuItem>
        <Divider />
        <MenuItem onClick={logoutClicked}>Sign out</MenuItem>
      </Menu>
    </div>
  )
}
