/* eslint-disable @typescript-eslint/no-explicit-any */
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordRequestSchema } from '../auth.schema'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Link from '@mui/material/Link'
import { ModalFormType } from '../auth.model'
import { authComponentChange, resetPasswordRequest } from '../auth.slice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../store'
import { resetPasswordRequestErrorSelector, resetPasswordRequestInProgressSelector, resetPasswordRequestSuccessSelector } from '../auth.selectors'

export const ResetPasswordComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isError = useSelector(resetPasswordRequestErrorSelector)
  const isSuccess = useSelector(resetPasswordRequestSuccessSelector)
  const isLoading = useSelector(resetPasswordRequestInProgressSelector)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(resetPasswordRequestSchema),
  })

  const onSubmit = async (payload: any) => {
    try {
      dispatch(resetPasswordRequest(payload))
      // console.log(result)
      // console.log('onSubmit', payload)
    } catch (e) {
      console.log('ERR', e)
    }
  }

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <h1>Reset password</h1>
      {/* <p>Not implemented yet</p> */}
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        {!isSuccess && (
          <TextField
            {...register('email')}
            margin="normal"
            required
            fullWidth
            label="Email"
            type="text"
            aria-autocomplete="none"
            autoComplete="off"
            error={errors?.email ? true : false}
            helperText={errors?.email?.message?.toString()}
          />
        )}
        {isSuccess && (
          <Alert severity="success">
            <AlertTitle>Check your email</AlertTitle>
            We sent you a message with a link to change the password
          </Alert>
        )}
        {isError && (
          <Alert severity="error">
            <AlertTitle>Ooops</AlertTitle>
            Something went wrong
          </Alert>
        )}
        {!isSuccess && (
          <>
            <Button disabled={!isValid || isLoading} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Reset password
            </Button>
            <ul>
              <li>
                <Link underline="always" onClick={() => dispatch(authComponentChange(ModalFormType.SIGNIN))}>
                  Sign in
                </Link>
              </li>
            </ul>
          </>
        )}
      </Box>
    </>
  )
}
