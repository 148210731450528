/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { http } from '../../common/http/http.service'
import { AuthConfirmEmail, ModalFormType } from './auth.model'
// import type { PayloadAction } from '@reduxjs/toolkit'
// import { http } from '../../../common/http/http.service'

export type AuthState = {
  isModalOpen: boolean
  token: string | null
  authComponent: ModalFormType
  signInInProgress: boolean
  signInSuccess: boolean
  signInError: boolean
  signInErrorMessage: string | null
  signUpInProgress: boolean
  signUpSuccess: boolean
  signUpError: boolean
  signUpErrorMessage: string | null
  emailConfirmInProgress: boolean
  emailConfirmSuccess: boolean
  emailConfirmError: boolean
  emailConfirmErrorMessage: string | null
  resetPasswordRequestInProgress: boolean
  resetPasswordRequestSuccess: boolean
  resetPasswordRequestError: boolean
  resetPasswordRequestErrorMessage: string | null
  resetPasswordInProgress: boolean
  resetPasswordSuccess: boolean
  resetPasswordError: boolean
  resetPasswordErrorMessage: string | null
}

const initialState: AuthState = {
  isModalOpen: false,
  token: null,
  authComponent: ModalFormType.SIGNIN,
  signInInProgress: false,
  signInSuccess: false,
  signInError: false,
  signInErrorMessage: null,
  signUpInProgress: false,
  signUpSuccess: false,
  signUpError: false,
  signUpErrorMessage: null,
  emailConfirmInProgress: false,
  emailConfirmSuccess: false,
  emailConfirmError: false,
  emailConfirmErrorMessage: null,
  resetPasswordRequestInProgress: false,
  resetPasswordRequestSuccess: false,
  resetPasswordRequestError: false,
  resetPasswordRequestErrorMessage: null,
  resetPasswordInProgress: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resetPasswordErrorMessage: null,
}

export const signUp = createAsyncThunk('auth/SignUp', async (payload: any) => {
  const response = await http().post('/user/signup', payload)
  return response.data
})

export const confirmEmail = createAsyncThunk('auth/Confirm', async (payload: AuthConfirmEmail) => {
  const { email, confirmationCode } = payload
  const response = await http().get(`/user/confirm/${email}/${confirmationCode}`)
  return response.data
})

export const signIn = createAsyncThunk('auth/SignIn', async (payload: any) => {
  const response = await http().post('/user/signin', payload)
  return response.data
})

export const resetPasswordRequest = createAsyncThunk('auth/ResetPasswordRequest', async (payload: any) => {
  const response = await http().post('/user/passwordResetRequest', payload)
  return response.data
})

export const passwordReset = createAsyncThunk('auth/passwordReset', async (payload: any) => {
  const response = await http().post('/user/passwordReset', payload)
  return response.data
})

export const authState = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    open: (state: AuthState, action: PayloadAction<any>) => {
      const { payload } = action

      state.isModalOpen = true
      state.authComponent = payload
    },
    close: (state: AuthState) => {
      state.isModalOpen = false
      state.authComponent = ModalFormType.SIGNIN
      state.signUpSuccess = false
      state.signUpError = false
      state.signUpInProgress = false
      state.signUpErrorMessage = null
      state.signInSuccess = false
      state.signInError = false
      state.signInInProgress = false
      state.signInErrorMessage = null
    },
    authComponentChange: (state: AuthState, action: PayloadAction<ModalFormType>) => {
      const { payload } = action
      state.authComponent = payload
    },
    clearToken: (state: AuthState) => {
      state.token = null
    },
  },
  extraReducers: builder => {
    builder.addCase(signUp.pending, (state: AuthState) => {
      state.signUpInProgress = true
      state.signUpSuccess = false
      state.signUpError = false
      state.signUpErrorMessage = null
    })
    builder.addCase(signUp.fulfilled, (state: AuthState) => {
      // console.log(action)
      state.signUpInProgress = false
      state.signUpSuccess = true
    })
    builder.addCase(signUp.rejected, (state: AuthState) => {
      // console.log(action)
      state.signUpInProgress = false
      state.signUpError = true
    })
    builder.addCase(signIn.pending, (state: AuthState) => {
      state.signInInProgress = true
      state.signInSuccess = false
      state.signInError = false
      state.signInErrorMessage = null
    })
    builder.addCase(signIn.fulfilled, (state: AuthState, action: any) => {
      const { payload } = action
      // console.log(action)
      state.token = payload.token
      state.signInInProgress = false
      state.signInSuccess = true
    })
    builder.addCase(signIn.rejected, (state: AuthState) => {
      // console.log(action)
      state.signInInProgress = false
      state.signInError = true
    })
    builder.addCase(confirmEmail.pending, (state: AuthState) => {
      state.emailConfirmInProgress = true
      state.emailConfirmSuccess = false
      state.emailConfirmError = false
    })
    builder.addCase(confirmEmail.fulfilled, (state: AuthState) => {
      state.emailConfirmInProgress = false
      state.emailConfirmSuccess = true
    })
    builder.addCase(confirmEmail.rejected, (state: AuthState) => {
      state.emailConfirmInProgress = false
      state.emailConfirmError = true
    })
    builder.addCase(resetPasswordRequest.pending, (state: AuthState) => {
      state.resetPasswordRequestInProgress = true
      state.resetPasswordRequestSuccess = false
      state.resetPasswordRequestError = false
      state.resetPasswordRequestErrorMessage = null
    })
    builder.addCase(resetPasswordRequest.fulfilled, (state: AuthState) => {
      state.resetPasswordRequestInProgress = false
      state.resetPasswordRequestSuccess = true
    })
    builder.addCase(resetPasswordRequest.rejected, (state: AuthState) => {
      state.resetPasswordRequestInProgress = false
      state.resetPasswordRequestError = true
    })
    builder.addCase(passwordReset.pending, (state: AuthState) => {
      state.resetPasswordInProgress = true
      state.resetPasswordSuccess = false
      state.resetPasswordError = false
      state.resetPasswordErrorMessage = null
    })
    builder.addCase(passwordReset.fulfilled, (state: AuthState) => {
      state.resetPasswordInProgress = false
      state.resetPasswordSuccess = true
    })
    builder.addCase(passwordReset.rejected, (state: AuthState) => {
      state.resetPasswordInProgress = false
      state.resetPasswordError = true
    })
  },
})

export const { open, close, authComponentChange, clearToken } = authState.actions

export default authState.reducer
