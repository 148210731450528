/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box'
import { AppDispatch } from '../../../../../store'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import { close } from '../../../../auth/auth.slice'
import { TextareaAutosize } from '@mui/material'
import { useRef } from 'react'
import { setParkGeoAreaFromClipboard } from '../../../parks/parks.slice'

export const AddGeoFromClipboard = () => {
  const dispatch = useDispatch<AppDispatch>()
  const ref = useRef<HTMLTextAreaElement | null>(null)

  const onSubmit = async (payload: any) => {
    try {
      // dispatch(signIn(payload))
      // console.log(result)
      console.log('onSubmit', payload)
    } catch (e) {
      // console.log('ERR', e)
    }
  }
  const handleCancel = () => {
    dispatch(close())
  }

  const handleAdd = () => {
    if (ref.current === null) return

    const textarea = ref.current
    dispatch(close())
    dispatch(setParkGeoAreaFromClipboard([textarea.value]))
  }

  return (
    <>
      <h1>Add geo area from clipboard</h1>
      <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1 }}>
        <TextareaAutosize autoComplete="off" style={{ width: '100%', height: '400px' }} ref={ref} />
        <Box display="flex" justifyContent={'flex-end'}>
          <Button onClick={handleCancel} variant="outlined" sx={{ mt: 2, mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleAdd} variant="contained" sx={{ mt: 2 }}>
            Add
          </Button>
        </Box>
      </Box>
    </>
  )
}
