export enum ModalFormType {
  SIGNIN = 'SignIn',
  SIGNUP = 'SignUp',
  RESET_PASSWORD = 'ResetPassword',
  ADD_GEO_FROM_CLIPBOARD = 'AddGeoFromClipboard',
}

export type AuthConfirmEmail = {
  email: string
  confirmationCode: string
}
