import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { ParksState } from './parks.slice'

const selectParksState = (state: RootState) => state.parks

export const isLoadingSelector = createSelector(selectParksState, (state: ParksState) => state.loadingDetails)

export const selectedParkDetailsSelector = createSelector(selectParksState, (state: ParksState) => state.selectedParkDetails)

export const selectedParkActivationsSelector = createSelector(selectParksState, (state: ParksState) => state.selectedParkActivations)

export const selectedParkRelationsSelector = createSelector(selectParksState, (state: ParksState) => state.selectedParkRelations)

export const selectedParkCommentsSelector = createSelector(selectParksState, (state: ParksState) => state.selectedParkComments)

export const selectedParkSummitsWithinSelector = createSelector(selectParksState, (state: ParksState) => state.selectedParkSummitsWithin)

export const parksForBoundsSelector = createSelector(selectParksState, (state: ParksState) => state.parksForBounds)

export const parkAreasForBoundsSelector = createSelector(selectParksState, (state: ParksState) => state.parkAreasForBounds)

export const selectedParkDetailsCountiesSelector = createSelector(selectParksState, (state: ParksState) => state.parkDetailsCounties)

export const selectedParkDetailsCommunesSelector = createSelector(selectParksState, (state: ParksState) => state.parkDetailsCommunes)

export const geoFromClibboardAreaSelector = createSelector(selectParksState, (state: ParksState) => state.parkGeoAreaFromClipboard)
