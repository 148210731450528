import { useDispatch, useSelector } from 'react-redux'
import Modal from '@mui/material/Modal'

import { authComponentSelector, isModalOpenSelector } from '../auth.selectors'
import { close } from '../auth.slice'
import { SignInComponent } from './SignIn.component'
import CssBaseline from '@mui/material/CssBaseline'
import { ModalFormType } from '../auth.model'
import { SignUpComponent } from './SignUp.component'
import { ResetPasswordComponent } from './ResetPassword.component'
import { ModalFormContainer } from '../../../common/layout/Modal.styles'
import { AddGeoFromClipboard } from '../../map/aside/components/park/AddGeoFromClipboard'

export const ModalAuthenticationComponent = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(isModalOpenSelector)
  // const [authComponent, setAuthComponent] = useState<ModalFormType>(ModalFormType.SIGNIN)
  const authComponent = useSelector(authComponentSelector)
  const handleClose = () => dispatch(close())

  const ComponentView = {
    [ModalFormType.SIGNIN]: SignInComponent,
    [ModalFormType.SIGNUP]: SignUpComponent,
    [ModalFormType.RESET_PASSWORD]: ResetPasswordComponent,
    [ModalFormType.ADD_GEO_FROM_CLIPBOARD]: AddGeoFromClipboard,
  }

  const Component = ComponentView[authComponent]

  if (!isOpen) return

  return (
    <>
      <CssBaseline />
      <Modal keepMounted open={isOpen} onClose={handleClose}>
        <ModalFormContainer elevation={3} className={authComponent === ModalFormType.ADD_GEO_FROM_CLIPBOARD ? 'addGeo' : ''}>
          <Component />
        </ModalFormContainer>
      </Modal>
    </>
  )
}
